.App {
  margin: 0 auto;
  // padding-bottom: 100px;
  min-height: 100vh;
  // text-align: center;
 }

body {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.mainContent {
  padding-left: calc(100vw - 100%);
}

p a {
  text-decoration: underline;
}

body, html {
  background: #f0f2f5;
  min-height: 100vh;
  width: 100%;
  overflow-wrap: break-word;
}

// .SlotMachine {
//   .slot {
//     position: relative;
//     display: inline-block;
//     height: 100px;
//     width: 80px;
//   }
  
//   section {
//     position: absolute;
//     border-radius: 15px !important;
//     border: 3px solid black !important;
//     width: 70px;
//     height: 70px;
//     overflow: hidden;
//     background-color: grey;
//     border-radius: 2px;
//     border: 1px solid lightgrey;
//     color: white;
//     font-family: sans-serif;
//     text-align: center;
//     font-size: 25px;
//     line-height: 60px;
//     cursor: default;
//   }
  
//   .container {
//     position: absolute;
//     top: 2px;
//     width: 100%;
//     transition: top ease-in-out 0.5s;
//     text-align: center;
//   }
  
//   .roll {
//     width: 215px;
//     cursor: pointer;
//     background-color: yellow;
//     padding: 10px;
//     text-align: center;
//     font-size: 20px;
//     border-radius: 20px;
//     border: 3px solid black;
//   }
  
//   .rolling {
//     animation: blinkingText 1.2s infinite;
//   }
  
//   @keyframes blinkingText {
//     0% {
//       color: #000;
//     }
//     49% {
//       color: #000;
//     }
//     60% {
//       color: transparent;
//     }
//     99% {
//       color: transparent;
//     }
//     100% {
//       color: #000;
//     }
//   }
// }

input[type=number]::-webkit-inner-spin-button {
  opacity: 1;
}

input[type=number] {
  outline: none;
}

#root {
  min-height: 100vh;
  width: 100%;
}

header {
  & > div {
    display: flex;
    height: 100%;
  }
  
  .center {
    flex: 1 0 0%;
    align-items: flex-start;
    justify-content: center;
    line-height: 1.15;
    display: flex;
    flex-direction: column;
    gap: 3px;
    
    * {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      margin: 0;
    }
  }
  
  .side {
    gap: 5px;
    flex: 1;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    padding: 5px 0;
    align-content: center;
    // background: lightpink;
    // left: calc(calc(100vw - 100%) * 1px);
  }
}

.ant-page-header-heading-left {
  flex-wrap: wrap;
}

@media only screen and (max-width: 800px) {
  .ant-page-header-heading-extra { display: none !important; }
  
  .ant-layout-header {
    padding: 0 15px !important;
    h4 {
      display: none;
    }
    
    .ant-typography {
      font-size: 16px;
      text-align: right;
    }
  }
  
  .walletBalance {
    display: none !important;
  }
  
  .profilePage .header {
    width: 100%;
    .addressUnit, .addressText, .ant-typography {
      width: 100%;
    }
  }
}

.profilePage {
  .header {
    .addressUnit {
      flex-direction: column;
      align-items: flex-start;
      
      canvas {
        height: 50px !important;
        width: 50px !important;
        border-radius: 50%;
      }
      
      * {
        line-height: 1 !important;
      }
    }
  }
}
.ant-layout-content {
  // padding: 25px max(calc((100vw - 750px) / 2), 15px) 25px;
  width: min(750px, calc(100% - 30px));
  // width: 100%;
  margin: 0 auto;
}

.ant-page-header-heading-extra, header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin: 0 !important;
  // margin-bottom: 0;
  
  & > * {
    margin: 0 !important;
    line-height: 1 !important;
  }
  
  * {
    line-height: 1 !important;
  }
  
  .logoutButton {
    margin: 0;
  }
  
  .anticon {
    display: flex;
    height: 15px;
    
    svg {
      height: 100%;
    }
  }
  
  .walletBalance, .ant-typography {
    display: flex;
    align-items: center;
  }
  
  .networkSwitchDropdown {
    display: none;
  }
  
  .walletBalance {
    font-size: 16px !important;
  }
  
  .anticon-copy {
    display: none;
  }
  
  .addressUnit > * {
    font-size: 20px !important;
    
    canvas {
      height: 20px !important;
      width: 20px !important;
    }
  }
}

.accountUnit {
  display: flex;
  
  .anticon-wallet {
    display: none;
  }
}

.topRight {
  & > div > div > span {
    display: flex;
  }
}

.addressUnit {
  display: flex;
  align-items: center;
  gap: 10px;
  
  & > * {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
}

@keyframes fadeIn {
  from {
    /* transform: translateY(3px); */
    opacity: 0;
    opacity: 1\9;
    /* IE9 only */
  }
  to {
    /* transform: translateY(0); */
    opacity: 1;
  }
}

@keyframes bgcolor {
  0% {
    background-color: #21094e;
  }

  30% {
    background-color: #21094e;
  }

  60% {
    background-color: #4ca1a3;
  }

  90% {
    background-color: #f35;
  }

  100% {
    background-color: #864cbf;
  }
}

.hover-shine:hover {
  background: linear-gradient(90deg, #0000 33%, rgba(255, 255, 255, 0.22) 50%, #0000 66%) #f35;
  background-size: 300% 100%;
  animation: shine 1s infinite;
}

.shine-rarity-bg {
  background: linear-gradient(90deg, #0000 33%, rgba(255, 255, 255, 0.22) 50%, #0000 66%) #f35;
  background-size: 300% 100%;
  animation: shine 1s infinite;
}

.paddingYRarityTag {
  /* padding-top: 6px;
  padding-bottom: 5px; */
}

.pricelessssbg {
  background-color: #812ddd;
}

.pricelessssglow {
  box-shadow: 0 0 26px 0 #b741ff;
}

.pricess-text-color {
  color: #e6a2ff;
}

.legendarybg {
  background-color: #ff1664;
}

.legendaryglow {
  box-shadow: 0 0 26px 0 #ff1664;
}

.legendary-price-color {
  color: #ffb7cf;
}

.epicbg {
  background-color: #168eff;
}

.epicglow {
  box-shadow: 0 0 26px 0 #168eff;
}

.epic-price-color {
  color: #c4f6ff;
}

.rarebg {
  background-color:#ffd04f;
}

.rareglow {
  box-shadow: 0 0 26px 0 #ffd04f;
}

.rare-price-color {
  color: rgb(254, 255, 208);
}

.pixelated {
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}

.banner-img {
  transform: scaleX(-1)
}

.banner-img:hover {
  transform: scaleX(1)
}


.box {
  background: linear-gradient(90deg, #0000 33%, rgba(255, 255, 255, 0.22) 50%, #0000 66%) #f35;
  background-size: 300% 100%;
  animation: shine 2s infinite;
}

/* styling paper */
.letter_paper {
  position: relative;
  margin: 20px auto;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: white;
  box-shadow: 0 32px 34px 0 rgba(0, 0, 0, 0.07);
}

/* styling red vertical line */
.letter_paper::before {
  content: "";
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 40px;
  background-color: rgba(255, 0, 0, 0.6);
}

/* styling blue horizontal lines */
.letter_pattern {
  height: 100%;
  background-image: repeating-linear-gradient(white 0px, white 24px, teal 25px);
}

/* styling text content */
.letter_content {
  padding-top: 6px;
  padding-left: 56px;
  padding-right: 16px;
  line-height: 25px;
  font-size: 17px;
}

.neonText {
  color: rgba(255, 0, 153, 0);
  text-shadow: 0 0 2px rgba(255, 0, 153, 0), 0 0 4px rgba(255, 0, 153, 0), 0 0 6px rgba(255, 0, 153, 0),
    0 0 8px rgba(255, 0, 153, 0), 0 0 10px rgba(255, 0, 153, 0);
}

/* Additional styling */

.pulse {
  animation: pulsate 0 ease-in-out infinite alternate;
}
@keyframes pulsate {
  100% {
    text-shadow: 0 0 2px rgba(255, 0, 153, 1), 0 0 4px rgba(255, 0, 153, 1), 0 0 6px rgba(255, 0, 153, 1),
      0 0 8px rgba(255, 0, 153, 1), 0 0 10px rgba(255, 0, 153, 1);
  }

  0% {
    text-shadow: 0 0 2px rgba(255, 0, 153, 0), 0 0 4px rgba(255, 0, 153, 0), 0 0 6px rgba(255, 0, 153, 0),
      0 0 8px rgba(255, 0, 153, 0), 0 0 10px rgba(255, 0, 153, 0);
  }
}

.shimmer {
  margin: 0 auto;
  display: inline;
  margin-bottom: 0;
}
.shimmer {
  text-align: center;
  color: rgba(255, 255, 255, 0.1);
  background: -webkit-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.2, #fff));
  background: -moz-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.2, #fff));
  background: gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.2, #fff));
  -webkit-background-size: 50px 100%;
  -moz-background-size: 50px 100%;
  background-size: 50px 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-animation-name: shimmer;
  -moz-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: #222;
}

@-moz-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-webkit-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-o-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

@keyframes shine {
  0% {
    background-position: right;
  }
  /*100% {
    background-position: left; it's the default value, no need to define it
  }*/
}

@keyframes pulse-anim {
  0% {
    -webkit-box-shadow: 0 0 0 0 #ff1664;
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}

.ready-to-open-bar {
  -webkit-animation: bgcolor 8s infinite;
  animation: bgcolor 8s infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.add-glow {
  box-shadow: rgba(255, 233, 36, 0.5) 0px 10px 40px -10px;
}

.add-glow:hover {
  box-shadow: rgba(255, 233, 36, 0.65) 0px 13px 60px -10px;
}

/* .glass-btn {
  box-shadow: 0px 20px 20px -17px rgba(255, 159, 16, 0.53);
}
.glass-btn:hover {
  box-shadow: 0px 20px 35px -16px rgba(255, 159, 16, 0.6);
}
 */

.parallax {
  display: block;
  height: auto;
  position: relative;
  width: auto;
}
.parallax-content {
  height: auto;
  transform: perspective(1600px);
  transform-style: preserve-3d;
  transition: all 0.4s ease;
  width: 100%;
}
.parallax-content:before {
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.parallax-front {
  align-items: center;
  color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  text-align: center;
  text-shadow: 0 0 3rem rgba(69, 77, 93, 0.95);
  top: 0;
  transform: translateZ(16px) scale(0.9);
  transition: all 0.4s ease;
  width: 100%;
  z-index: 1;
}
.parallax-top-left {
  height: 50%;
  left: 0;
  position: absolute;
  top: 0;
  width: 50%;
  z-index: 300;
  cursor: pointer;
}
.parallax-top-left:hover ~ .parallax-content {
  transform: perspective(1600px) rotateX(-5deg) rotateY(5deg);
}
.parallax-top-left:hover ~ .parallax-content:before {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 50%);
}
.parallax-top-left:hover ~ .parallax-content .parallax-front {
  transform: translate3d(-0.1px, -0.1px, 16px) scale(0.9);
}
.parallax-top-right {
  height: 50%;
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  z-index: 300;
  cursor: pointer;
}
.parallax-top-right:hover ~ .parallax-content {
  transform: perspective(1600px) rotateX(-5deg) rotateY(-5deg);
}
.parallax-top-right:hover ~ .parallax-content:before {
  background: linear-gradient(-135deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 50%);
}
.parallax-top-right:hover ~ .parallax-content .parallax-front {
  transform: translate3d(0.1px, -0.1px, 16px) scale(0.9);
}
.parallax-bottom-left {
  bottom: 0;
  height: 50%;
  left: 0;
  position: absolute;
  width: 50%;
  z-index: 300;
  cursor: pointer;
}
.parallax-bottom-left:hover ~ .parallax-content {
  transform: perspective(1600px) rotateX(5deg) rotateY(5deg);
}
.parallax-bottom-left:hover ~ .parallax-content:before {
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 50%);
}
.parallax-bottom-left:hover ~ .parallax-content .parallax-front {
  transform: translate3d(-0.1px, 0.1px, 16px) scale(0.9);
}
.parallax-bottom-right {
  bottom: 0;
  height: 50%;
  position: absolute;
  right: 0;
  width: 50%;
  z-index: 300;
  cursor: pointer;
}
.parallax-bottom-right:hover ~ .parallax-content {
  transform: perspective(1600px) rotateX(5deg) rotateY(-5deg);
}
.parallax-bottom-right:hover ~ .parallax-content:before {
  background: linear-gradient(-45deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 50%);
}

.parallax-bottom-right:hover ~ .parallax-content .parallax-front {
  transform: translate3d(0.1px, 0.1px, 16px) scale(0.9);
}

.marquee {
  position: relative;
  overflow: hidden;
  --offset: 20vw;
  --move-initial: calc(-25% + var(--offset));
  --move-final: calc(-50% + var(--offset));
  line-height: 1.2;
  user-select: none;
  pointer-events: none;
}

.marquee__inner {
  width: fit-content;
  display: flex;
  position: relative;
  transform: translate3d(var(--move-initial), 0, 0);
  animation: marquee 8s linear infinite;
  animation-play-state: running;
}

.marquee div.text {
  display: flex;
  font-size: 1rem;
  padding: 0 1rem;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
}

.marquee span {
  -webkit-text-stroke: 1px #fff;
  color: transparent;
  padding-left: 1rem;
  padding-right: 1rem;
}

.marquee.invert div.text {
  -webkit-text-stroke: 1px #fff;
  color: transparent;
}
.marquee.invert span {
  color: #fff;
  -webkit-text-stroke: 0px #fff;
}

.marquee.delay .marquee__inner {
  animation-delay: -0.5s;
}

.marquee__inner .marquee:hover .marquee__inner {
  animation-play-state: running;
}