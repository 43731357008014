form.amendForm {
  // width: 750px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  gap: 1rem;
  flex-direction: row;
  margin-bottom: 25px;
  position: relative;
  
  
  .newAmendUnitContainer {
    overflow: hidden;
  }
  
  .ant-result {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  
  &:not(.success) .ant-result {
    display: none;
  }
  
  &.success > *:not(.ant-result) {
    opacity: 0.1;
  }
  
  &.success .controls {
    display: none;
  }
  
  fieldset {
    padding: 25px;
    display: flex;
    flex-direction: column;
    gap: 2rem
  }
  
  legend {
    padding: 0 5px;
    font-size: 18px;
  }
  
  .newAmendmentUnit {
    width: 750px;
    height: 750px;
    position: relative;
    border: 0;
    overflow: hidden;
    margin: 0 auto;
    transform: scale(min(calc(var(--window-width) / 750), 1));
    transform-origin: top left;
    // * {
    //   overflow: hidden;
    //   margin: 0;
    //   padding: 0;
    //   border: 0;
    //   overflow-wrap: break-word;
    // }
    
    &.calldataText {
      display: flex;
      flex-direction: column;
    }
  }
  
  .header {
    font-family: "Times New Roman", serif;
    font-family: monospace;
    background: transparent;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: space-between;
    gap: 1rem;
    font-size: 14px;
    padding: 10px 0.5rem;
    
    .author, .recipient {
      display: flex;
      align-items: stretch;
      gap: 0.5rem;
    }
    
    input {
      border: 0;
      border-bottom: 1px solid rgb(0,0,0,0.1);
      font-family: monospace;
      color: #000;
      font-size: 14px;
      // padding: 0 5px;
      flex: 0.8;
    }
    
    .submitArea {
      flex: 1;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 1rem;
      
      button {
        // flex: 0.5;
        width: 100%;
      }
    }
  }
  
  .cmp-address-wrapper {
    .indicator {
      margin: 0;
    }
    
    .blockies {
      border-radius: 0;
      box-shadow: none;
      margin: 0;
    }
    
    .input-wrapper {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    
    input[name=ethereum] {
      font-family: monospace;
      color: #000;
      font-size: 14px;
      margin: 0;
      width: auto;
      flex: 1;
    }
  }
  
  .amendmentText {
    // position: absolute;
    border: 0;
    padding: 0;
    height: 640px;
    position: relative;
    font-family: "Times New Roman", serif;
    padding: 40px;
    font-size: 32px;
    line-height: 1.3;
    overflow-x: hidden;
    overflow-y: auto;
    // border: 0;
    background: transparent;
    color: inherit;
    overflow-x: hidden;
    overflow-y: auto;
    // overflow-y: auto;
    
    resize: none;
    outline: none;
    
    &.calldataText {
      position: static;
      // font-size: 20px;
      // line-height: 24px;
      // padding: 20px;
      // min-height: 200px;
    }
  }
  
  .amendmentFooter {
    // display: none !important;
    // height: 110px;
    font-family: Helvetica, Arial, sans-serif;
    line-height: 1;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    font-size: 26px;
    color: #ffffff;
    
    border-top-width: 1px;
    border-top-style: solid;
    font-family: Helvetica;
    font-weight: normal;
    font-size: 14px;
    
    a, .ant-typography {
      color: inherit !important;
    }
    
    canvas {
      display: none !important;
    }
    
    .addressUnit {
      grid-gap: 5px;
    }
    
    .rightCol {
      display: flex;
      flex-direction: column;
      // font-weight: lighter;
      flex-shrink: 0;
      font-size: 16px;
      height: 100%;
      gap: 1px;
      justify-content: center;
      text-align: right;
    }
  }
  
  .controls {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .inputs {
    display: flex;
    flex-direction: column;
    flex: 1;
    
    font-size: 14px;
    
    .doodle-border-bottom {
      font-weight: bold;
    }
    
    label {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
    
    .doodle-border-bottom {
      margin: 2px 0;
    }
    
    .colorOptions, .gradientOptions {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 5px;
      flex-direction: column;
      
      .colorPickerContainer {
        width: 20px;
        height: 20px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid black;
      }
      
      input[type=color] {
        width: 50px;
        height: 50px;
        flex-shrink: 0;
      }
    }
  }
  
  .submitArea {
    button[type=submit] {
      min-height: 50px;
      flex: 1 0 200px;
      width: 100%;
      font-weight: bold;
    }
  }
}

@media only screen and (max-width: 800px) {
  form.amendForm {
    flex-direction: column;
    
    .controls {
      flex-direction: row;
    }
  }
}