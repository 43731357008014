@import './CustomFonts.scss';

.messageContainer {
  width: 390px;
  height: calc(5/4* min(var(--small-scale), var(--max-scale)));
  // height: min(calc(var(--window-width) * 1px), 750px);
  overflow: hidden;
  --small-scale: calc((var(--window-width) * 1px - 30px));
  --max-scale: 500px;
  width: min(var(--small-scale), var(--max-scale));
}

.message:not(.noScale) {
  --small-scale: calc((var(--window-width) - 30) / 390);
  --max-scale: calc(500 / 390);
  transform: scale(min(var(--small-scale), var(--max-scale)));
}

.message {
  // transform: scale(0.5);
  width: 390px;
  height: calc(5/4* 390px);
  // width: min(700px, 100%);
  // aspect-ratio: 1;
  // transform: scale(min(calc(var(--window-width) / 750), 1));
  // transform: scale(min(calc(500 / 750), 1))/;
  transform-origin: top left;
  display: flex;
  flex-direction: column;
  
  &[data-fancy-message-id="0"] {
    .messageText {
      overflow-y: auto;
    }
  }
  
  .messageText {
    flex: 1;
    font-family: 'IBM Plex Sans_ww4az6WSyhEj3oM7';
    padding: 16px;
    padding-bottom: 0;
    font-size: 16px;
    line-height: 1.5;
    overflow-x: hidden;
    // overflow-y: auto;
    overflow-y: hidden;

    .messageTextInner {
      white-space: pre-wrap;
      overflow-wrap: break-word;
      // display:-webkit-box;
      // -webkit-line-clamp:15;
      // -webkit-box-orient:vertical;
      overflow: hidden;
    }
    
    textarea.messageTextInner {
      resize: none;
      width: 100%;
      height: 100%;
      border: 0;
      outline: none;
      background: transparent;
      color: inherit;
      padding: 0;
    }
    
    .simplebar-content {
      display: flex;
    }
    .simplebar-track.simplebar-vertical {width: 10px;}
  }
  
  .messageFooter {
    white-space: pre;
    background: rgba(0,0,0,.5);
    color: #fff;
    padding: 16px;
    font-size: 12px;
    line-height: calc(4/3);
    display: flex;
    flex-direction: column;
    
    .tokenName {
      font-family: "IBM Plex Sans Condensed_ww4az6WSyhEj3oM7";
      letter-spacing: 1.25px;
      // font-weight: 500;
      margin-bottom: 8px;
    }
    
    .tokenMeta {
      font-family: "IBM Plex Mono_ww4az6WSyhEj3oM7", "Menlo", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", Courier, monospace;
      white-space: pre;
      
      .ant-skeleton-with-avatar {
        display: none;
      }
    }
    
    .addressUnit {
      display: inline-flex;
      
      & > span:first-child {
        display: none;
      }
    }
    
    a, .ant-typography {
      color: inherit !important;
    }
    
    a {
      color: inherit !important;
    }
    
    canvas {
      display: none !important;
    }
    
    .addressUnit {
      grid-gap: 5px;
    }
  }
}
